'use strict';

const GDDLHelper = require('./helpers/helper');

var atrack;

/**
 * Initialize the GDDL analytics tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

const getCurrentPlacement = () => {
    const page = $('.page').data('action');
    switch (page) {
        case 'Product-Show':
            return 'pdp';
        case 'Wishlist-Show':
            return 'wishlist';
        case 'Product-ShowInCategory':
            return 'shop-the-look';
        default:
            return '';
    }
};

const getPlacement = (update) => {
    if (update) {
        sessionStorage.setItem('reservation_placement', getCurrentPlacement());
        return getCurrentPlacement();
    }
    return sessionStorage.getItem('reservation_placement') || getCurrentPlacement();
};

const initStartStep = () => {
    $('body').on('reservation:start', function (e, button) {
        const commerceData = GDDLHelper.fetchCommerceData(button.closest('.product-detail').find('meta[itemprom="analytics"]').first());
        const eventData = {
            event: 'tool-step',
            info: {
                name: 'reservation',
                placement: getPlacement(true),
                step_number: 1,
                step_name: 'check store stock',
                result: {
                    store: '',
                    store_is_favorite: ''
                },
                error_type: '',
                error_message: ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    });
};

const initVerifyStep = () => {
    $('body').on('reservation:step2', function (e, store, fav) {
        const commerceData = GDDLHelper.fetchCommerceData('#clickCollectModal .inventory-check-overview .inventory-check-product meta[itemprom="analytics"]');
        sessionStorage.setItem('reservation_store', JSON.stringify({
            store: store,
            store_is_favorite: fav
        }));
        const eventData = {
            event: 'tool-step',
            info: {
                name: 'reservation',
                placement: getPlacement(),
                step_number: 2,
                step_name: 'verify reservation',
                result: JSON.parse(sessionStorage.getItem('reservation_store')),
                error_type: '',
                error_message: ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    });
};

const initConfirmStep = () => {
    $('body').on('reservation:confirm', function (e, store, fav) {
        const commerceData = GDDLHelper.fetchCommerceData('#clickCollectModal .inventory-check-confirmation .inventory-check-product meta[itemprom="analytics"]');
        const eventData = {
            event: 'tool-laststep',
            info: {
                name: 'reservation',
                placement: getPlacement(),
                step_number: 3,
                step_name: 'confirm reservation',
                result: JSON.parse(sessionStorage.getItem('reservation_store')) || {
                    store: store,
                    store_is_favorite: fav
                },
                error_type: '',
                error_message: ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    });
};

const initReservationError = () => {
    $('body').on('reservation:error', function (e, title, msg) {
        const commerceData = GDDLHelper.fetchCommerceData('#clickCollectModal .inventory-check-overview .inventory-check-product meta[itemprom="analytics"]');
        const eventData = {
            event: 'tool-error',
            info: {
                name: 'reservation',
                placement: getPlacement(),
                step_number: 3,
                step_name: 'confirm reservation',
                result: JSON.parse(sessionStorage.getItem('reservation_store')) || {
                    store: '',
                    store_is_favorite: ''
                },
                error_type: 'user',
                error_message: msg || ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    }).on('reservation:cancelError', function (e, title, msg) {
        const commerceData = GDDLHelper.fetchCommerceData('#clickCollectModal .inventory-check-overview .inventory-check-product meta[itemprom="analytics"]');
        const eventData = {
            event: 'tool-error',
            info: {
                name: 'reservation',
                placement: 'my-reservations',
                step_number: 2,
                step_name: 'confirm reservation',
                result: {},
                error_type: 'system',
                error_message: msg || ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    });
};

const initReservationCancel = () => {
    $('body').on('reservation:cancel', function (e, orderRow) {
        const commerceData = GDDLHelper.fetchCommerceData(orderRow.find('meta[itemprom="analytics"]'));
        const eventData = {
            event: 'tool-step',
            info: {
                type: 'form',
                name: 'cancel-reservation',
                placement: 'my-reservations',
                step_number: 1,
                step_name: 'verify cancellation',
                result: {},
                error_type: '',
                error_message: ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    });
};

const initReservationConfirmCancel = () => {
    $('body').on('reservation:confirmCancel', function (e, orderRow) {
        const commerceData = GDDLHelper.fetchCommerceData(orderRow.find('meta[itemprom="analytics"]'));
        const eventData = {
            event: 'tool-laststep',
            info: {
                type: 'form',
                name: 'cancel-reservation',
                placement: 'my-reservations',
                step_number: 2,
                step_name: 'cancellation confirmed',
                result: {},
                error_type: '',
                error_message: ''
            },
            commerce: commerceData
        };

        atrack.trackEvent(eventData);
    });
};

const init = () => {
    initAnalyticsTracker();
    initStartStep();
    initVerifyStep();
    initConfirmStep();
    initReservationError();
    initReservationCancel();
    initReservationConfirmCancel();
};

$(() => {
    init();
});
